import tw, { styled } from 'twin.macro';

export const Box = styled.header(() => {
  const out = [
    tw`bg-white flex flex-col items-end max-w-full pt-4 pb-0 lg:pb-4 lg:block text-center static lg:bg-opacity-80 lg:sticky`,
    {
      minHeight: 135,
      left: 0,
      right: 0,
      top: 0,
      zIndex: 20,
    },
  ];
  return out;
});

type IHeaderTop = {
  onMouseOver: React.MouseEventHandler<HTMLDivElement>;
};
export const Top = styled.div<IHeaderTop>(() => tw`flex justify-center relative w-full lg:block`);

export const CellImage = styled.div(() => tw`block text-center`);
